<template>
  <div class="card border-0 bg-light shadow-sm h-100">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary">Additional Items</h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        You can attach additional items such as Forms, Resources and Terms and
        Conditions to your service. These items are automatically issued to
        clients who subscribe to the service.
      </p>
      <div class="row">
        <div class="col">
          <div v-if="showAddAdditionalItem">
            <div class="card">
              <div class="card-body">
                <div class="row mb-3">
                  <div class="col">
                    <label>Item Type</label>
                    <select
                      v-model="newAdditionalItem.entity_type"
                      class="form-control"
                    >
                      <option :value="undefined" selected disabled>
                        Select a Type
                      </option>
                      <option value="email">Email</option>
                      <option value="form">Form</option>
                      <option value="clinic_terms">Terms & Agreements</option>
                      <option value="resource">Resource</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3" v-if="newAdditionalItem.entity_type">
                  <div class="col">
                    Item
                    <div v-if="newAdditionalItem.entity_type === 'email'">
                      <email-template-select
                        v-model="newAdditionalItem.entity"
                        :type="'client'"
                      ></email-template-select>
                    </div>
                    <div v-if="newAdditionalItem.entity_type === 'form'">
                      <form-select
                        v-model="newAdditionalItem.entity"
                      ></form-select>
                    </div>
                    <div v-else-if="newAdditionalItem.entity_type === 'resource'">
                      <resource-select
                        v-model="newAdditionalItem.entity"
                      ></resource-select>
                    </div>
                    <div
                      v-else-if="newAdditionalItem.entity_type === 'clinic_terms'"
                    >
                      <clinic-terms-select
                        v-model="newAdditionalItem.entity"
                      ></clinic-terms-select>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                      <div v-if="newAdditionalItem.entity_type && newAdditionalItem.entity.id">
                        <div class="form-group" style="white-space: nowrap">
                          Send item
                          <input
                              type="number"
                              class="form-control d-inline mx-1"
                              v-model="newAdditionalItem.issue_days"
                              style="width: 75px"
                              min="0"
                          />
                          days
                          <select
                              class="form-control d-inline ms-1 ms-1 w-auto"
                              v-model="newAdditionalItem.issue_days_type"
                          >
                            <option value="after_booking" selected>
                              after booking
                            </option>
                            <option value="before_service_takes_place">
                              before service takes place
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <button
                        class="btn btn-primary float-right float-end ms-2 ms-2"
                        type="button"
                        @click="attachAdditionalItem"
                        :disabled="
                          !newAdditionalItem.entity_type ||
                          !newAdditionalItem.entity.id
                        "
                      >
                        <i class="far fa-times me-1 me-1"></i>
                        Attach Item
                      </button>

                      <button
                        class="btn btn-light float-right float-end"
                        type="button"
                        @click="cancelAdditionalItem"
                      >
                        <i class="far fa-times me-1 me-1"></i>
                        Cancel
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div v-else-if="value.length > 0">
            <div class="card mb-3">
              <div class="card-body my-0 p-0">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col" style="border-top: 0px">Type</th>
                      <th scope="col" style="border-top: 0px">Item</th>
                      <th scope="col" style="border-top: 0px">Send Date</th>
                      <th scope="col" style="border-top: 0px"></th>
                    </tr>
                  </thead>
                  <tbody class="text-left text-start">
                    <tr v-for="item in additionalItems" :key="item.id">
                      <td
                        v-if="editExistingAdditionalItem != item"
                        class="text-capitalize text-nowrap"
                      >
                        {{ item.entity_type | formatModel }}
                      </td>
                      <td class="col-2 cursor-pointer " v-else>
                        <select v-model="item.entity_type" class="form-control" @change="item.entity = {}">
                          <option :value="undefined" selected disabled>
                            Select a Type
                          </option>
                          <option value="email">Email</option>
                          <option value="form">Form</option>
                          <option value="clinic_terms">Terms & Agreements</option>
                          <option value="resource">Resource</option>
                        </select>
                      </td>
                      <td
                        v-if="editExistingAdditionalItem != item"
                        class="text-capitalize"
                      >
                        {{ item.entity ? item.entity.title : '' }}
                      </td>
                      <td class="col-4 cursor-pointer " v-else>
                        <div v-if="item.entity_type === 'email'" :class="validateEntityFlag ? 'border border-danger' : ''">
                          <email-template-select
                            v-model="item.entity"
                            :type="'client'"
                          ></email-template-select>
                        </div>
                        <div v-if="item.entity_type === 'form'" :class="validateEntityFlag ? 'border border-danger' : ''">
                            <form-select v-model="item.entity"></form-select>
                        </div>
                        <div v-else-if="item.entity_type === 'resource'">
                          <resource-select
                            v-model="item.entity"
                          ></resource-select>
                        </div>
                        <div v-else-if="item.entity_type === 'clinic_terms'">
                          <clinic-terms-select
                            v-model="item.entity"
                          ></clinic-terms-select>
                        </div>
                      </td>
                      <td v-if="editExistingAdditionalItem != item" class="text-wrap">
                        {{ item.issue_days }} days
                        {{ item.issue_days_type | formatModel }}
                      </td>
                      <td v-else>
                        <div class="form-group" style="white-space: nowrap">
                        Send item
                        <input
                          type="number"
                          class="form-control d-inline mx-1"
                          v-model="item.issue_days"
                          style="width: 75px"
                          min="0"
                        />
                        days
                        <select
                          class="form-control d-inline ms-1 ms-1 w-auto"
                          v-model="item.issue_days_type"
                        >
                          <option value="after_booking" selected>
                            after booking
                          </option>
                          <option value="before_service_takes_place">
                            before service takes place
                          </option>
                        </select>
                      </div>
                      </td>
                      <td class="text-nowrap text-right text-end">
                        <i
                          class="far fa-edit text-secondary cursor-pointer mx-1"
                          v-tooltip.bottom="'Edit this additional item'"
                          @click="editExistingAdditionalItem = item;"
                          v-if="editExistingAdditionalItem === null"
                        ></i>
                        <i
                          class="far fa-save text-secondary cursor-pointer mx-1"
                          v-tooltip.bottom="'Save changes'"
                          @click="updateItem(item)"
                          v-else
                        ></i>
                        <i
                          class="far fa-trash text-danger cursor-pointer"
                          v-tooltip.bottom="'Delete this additional item'"
                          @click="removeAdditionalItem(item)"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <p v-if="additionalItems.length === 0 && !showAddAdditionalItem" class="mb-2 text-center">
            No additional items.
          </p>
          <div class="d-flex w-100">
            <button
                class="btn btn-outline-primary ms-auto ms-auto"
                :class="additionalItems.length === 0 ? 'mx-auto' : 'ms-auto ms-auto'"
                v-if="!showAddAdditionalItem"
                type="button"
                @click="showAddAdditionalItem = true"
            >
              <i class="far fa-plus me-1 me-1"></i>
              Attach an additional item
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSelect from "@/views/globals/FormSelect";
import ResourceSelect from "@/views/globals/ResourceSelect";
import ClinicTermsSelect from "@/views/globals/ClinicTermsSelect";
import EmailTemplateSelect from "@/views/globals/EmailTemplateSelect";

export default {
  props: ["value"],
  data() {
    return {
      additionalItems: [],
      editExistingAdditionalItem: null,
      showAddAdditionalItem: false,
      newAdditionalItem: this.getDefaultAdditionalItem(),
      validateEntityFlag: false,
    };
  },
  methods: {
    getDefaultAdditionalItem() {
      return {
        issue_days: 1,
        issue_days_type: "after_booking",
        entity: {},
      };
    },
    attachAdditionalItem() {
      this.additionalItems.push(this.newAdditionalItem);
      this.showAddAdditionalItem = false;
      this.newAdditionalItem = this.getDefaultAdditionalItem();
      this.emitUpdatedInput();
    },
    cancelAdditionalItem() {
      this.showAddAdditionalItem = false;
      this.newAdditionalItem = this.getDefaultAdditionalItem();
    },
    removeAdditionalItem(item) {
      this.additionalItems = this.additionalItems.filter((i) => i !== item);
      this.emitUpdatedInput();
    },
    emitUpdatedInput() {
      this.additionalItems.forEach((item) => {
        if (item.entity) {
          item.entity_id = item.entity.id;
        }
      });
      this.$emit("input", this.additionalItems);
    },
    validateEntity(entity) {
      if(!entity || (entity && Object.keys(entity).length === 0 && entity.constructor === Object )) {
        return false;
      }
      return true;
    },
    updateItem(item) {
      if(this.validateEntity(item.entity)) {
        this.emitUpdatedInput();
        this.editExistingAdditionalItem = null;
        this.validateEntityFlag = false;
      }
      else {
        this.validateEntityFlag = true;
        //console.log("error validating entity");
      }
    }
  },
  filters: {
    formatModel(str) {
      return str.replace(/_/g, " ");
    },
  },
  computed: {
    path: function () {
      return this.$router.currentRoute.fullPath;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    token() {
      return this.$store.getters['auth/token'];
    },
  },
  created() {
    if (!this.value) {
      this.value = [];
    }
    this.additionalItems = this.value;
  },
  components: {
    EmailTemplateSelect,
    ClinicTermsSelect,
    ResourceSelect,
    FormSelect,
  },
};
</script>

<style scoped>
</style>
