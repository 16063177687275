<template>
  <div>
    <busy :visible="loading" />
    <select v-if="!loading"
        class="form-control"
        v-model="value.id"
        :required="req"
    >
      <option :value="undefined" selected disabled>Select an Email Template (Type: Client)</option>
      <option v-for="template in emailTemplates"
              :value="template.id">{{template.title}}</option>
    </select>
  </div>

</template>

<script>
export default {
  props: ["value", "req", "type"],
  data() {
    return {
      loading: true,
      emailTemplates: [],
    }
  },
  methods: {
    fetchEmailTemplates() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/email-templates/api").then(({ data }) => {
        const vm = this;
        this.emailTemplates = data.filter(function (template) {
          return template.type === vm.type;
        });
        this.loading = false;
      });
    },
  },
  watch: {
    value() {
      const vm = this;
      this.value = this.emailTemplates.filter(function (emailTemplate)  {
        return emailTemplate.id === vm.value.id;
      })[0];
      this.$emit("input", this.value);
    }
  },
  mounted() {
    this.fetchEmailTemplates();
  },
};
</script>