<template>
  <tree-select
      v-model="value.id"
      :disable-branch-nodes="true"
      :show-count="true"
      :show-count-of="'LEAF_DESCENDANTS'"
      :options="formSelectOptions"
      placeholder="Select a terms agreement from the list"
      :required="req"
  >
  </tree-select>
</template>

<script>
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  props: ["value", "req"],
  data() {
    return {
      terms: [],
      formSelectOptions: []
      }
  },
  methods: {
    fetchTerms () {
      this.$axios.get(process.env.VUE_APP_API_URL + "/settings/terms/fetch-terms-published")
        .then(({data}) => {
          this.terms = data;
          this.terms.forEach((f) => {
            this.formSelectOptions.push({
              label: f.title,
              id: f.id,
            });
          });
        });
    },
  },
  computed: {
  },
  mounted () {
    this.fetchTerms()
  },
  watch: {
    value() {
      const vm = this;
      this.value = this.terms.filter(function (term)  {
        return term.id === vm.value.id;
      })[0];
      this.$emit("input", this.value);
    }
  },
  components: {
    TreeSelect,
  }
};
</script>

<style>
  .vue-treeselect__control{
    height: 44px;
    padding-top: 4px;
    border: 1px solid #b0bfbf !important;
  }
</style>
