<template>
  <validation-observer ref="form">
    <form @submit.prevent="handleSubmit">
      <!-- DETAILS & COVER IMAGE -->
      <div class="row mb-4">
        <div class="col">
          <Details v-model="service" type="service" />
        </div>
        <div class="col">
          <CoverImage v-model="service" type="service" />
        </div>
      </div>

      <!-- PRICING & COMMS -->
      <div class="row mb-4">
        <div class="col">
          <Pricing v-model="service" type="service" />
        </div>
        <div class="col">
          <Communications v-model="service" />
        </div>
      </div>

      <!-- BOOKING & CLIENT BOOKING -->
      <div class="row mb-4">
        <div class="col">
          <BookingOptions v-model="service" />
        </div>
        <div class="col">
          <ClientBooking v-model="service" />
        </div>
      </div>

      <!-- AVAILABILITY -->
      <div class="row mb-4">
        <div class="col">
          <Availability v-model="service" />
        </div>
      </div>

      <!-- ADDITIONAL ITEMS -->
      <div class="row mb-4">
        <div class="col">
          <AdditionalItems v-model="service.additional_items" />
        </div>
      </div>

      <!-- PRACTITIONERS -->
      <div class="row mb-4" v-if="$store.getters['auth/user'].clinic.is_muc">
        <div class="col">
          <PractitionerOfferings v-model="service" />
        </div>
      </div>

      <!-- SAVE / CANCEL -->
      <div class="row mb-3">
        <div class="col text-right text-end">
          <button class="btn btn-light me-2 me-2" type="button" @click="cancelAdd">Cancel</button>
          <button type="submit" class="btn btn-primary">
            <i class="far fa-save me-2 me-2"></i>Save Service
          </button>
        </div>
      </div>
    </form>

  </validation-observer>
</template>

<script>



import BookingOptions from "./form-partials/BookingOptions";
import Communications from "./form-partials/Communications";
import Pricing from "./form-partials/Pricing";
import AdditionalItems from "./form-partials/AdditionalItems";
import Availability from "./form-partials/Availability";
import CoverImage from "./form-partials/CoverImage";
import Details from "./form-partials/Details";
import ClientBooking from "./form-partials/ClientBooking";
import PractitionerOfferings from "./form-partials/PractitionerOfferings";
export default {
  props: ["value"],
  data() {
    return {
      service: this.value,
    };
  },
  computed: {
    serviceInstalments() {
      return this.service.instalments
    }
  },
  watch: {
    service: {
      handler: function(newVal, oldVal) {
        if (!this.$store.getters['auth/user'].clinic.is_muc) {
          if (typeof this.service.practitioner_services[0] !== 'undefined') {
            this.service.practitioner_services[0].booking_options = this.service.booking_options;
          }
        }
        this.$emit("input", this.service);
      },
      deep: true
    },
    serviceInstalments: {
      handler: function(newVal, oldVal) {
        for (let i = 0; i < this.service.instalments.length; i++) {
          if (!this.service.practitioner_services[0].instalments[i]) {
            this.service.practitioner_services[0].instalments[i] = {}
          }
          this.service.practitioner_services[0].instalments[i].total = this.service.instalments[i].total
        }

        for (let i = this.service.instalments.length; i < this.service.practitioner_services[0].instalments.length; i++) {
          this.service.practitioner_services[0].instalments.splice(i, 1)
        }
      },
      deep: true
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate().then(success => {

        if (!success) {
          // Scroll to error
          const errors = Object.entries(this.$refs.form.refs).map(
              ([key, value]) => ({key, value})
          ).filter(error => {
            if (!error || !error.value || !error.value.failedRules) return false;
            return Object.keys(error.value.failedRules).length > 0;
          });
          if (errors && errors.length > 0) {
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }
          // Dont submit the form
          return;
        }
        this.$emit("submit");
      });
    },
    cancelAdd() {
      this.$emit("cancel");
    },
  },
  components: {
    PractitionerOfferings,
    ClientBooking,
    Details,
    Availability,
    Communications,
    BookingOptions,
    Pricing,
    CoverImage,
    AdditionalItems,
  }
};
</script>
